<template>
  <el-main class="rh-medform">
    <form-contratedit :ismeddetail="true" :routetolist="contRouteToList" />
  </el-main>
</template>
<script>
import FormContratedit from "@/components/rh/formulaires/FormContratedit";

export default {
  components: { "form-contratedit": FormContratedit },
  computed: {
    contRouteToList() {
      return {
        name: "med_contrats",
        params: {
          idmed: this.$route.params.idmed,
        },
      };
    },
  },
};
</script>
